.send-btn {
    background: #D65A31;
    color: #000000;
    border-color: #D65A31;
}

.send-btn:hover {
    color: #222831;
    background: #EEEEEE;
    border-color: #EEEEEE;
}

.contact-container .card-header {
    color: #fff;
    background: #222831;
}

.contact-container .card-body {
    background: #393E46;
    color: #fff;
}

@media (width >= 843px) {

    .contact-container {
        width: 720px;
        height: 563px;
    }
}