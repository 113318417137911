.card-detail-list {
  font-size: medium;
  list-style-type: none;
  padding-left: 0;
}

.card-detail-list li {
  font-size: 0.8rem;
}

.card-detail-list li a {
  color: blue;
  text-decoration: underline;
}

.card-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.project-card-container {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

@media (width <= 415px) and (width > 370px) {
  .card-holder {
    width: 280px;
  }
}

@media (width <= 370px) {
  .card-holder {
    max-width: 350px;
    min-width: 250px;
  }
}

@media (width > 415px) {
  .card-holder {
    width: 350px;
  }
}